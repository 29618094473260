<template>
  <div id="BehaviourTrees">
    <div class="w-100 d-inline-flex justify-content-between align-items-center mb-1">
      <h2>Behaviour Trees</h2>
      <b-button-group>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-success"
          size="sm"
          data-cy="btn-bt-create"
          @click="openCreateModal"
        >
          <feather-icon icon="PlusIcon" />
          Create Behaviour Tree
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          size="sm"
          @click="openImportModal"
        >
          <feather-icon icon="UploadIcon" />
          Import Behaviour Tree
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-info"
          size="sm"
          @click="fetchBehaviourTrees"
        >
          <feather-icon icon="RefreshCwIcon" />
        </b-button>
      </b-button-group>
    </div>

    <div v-if="loading">
      <div class="w-100 d-inline-flex justify-content-center mt-4">
        <div class="d-flex flex-column align-items-center">
          <h5 class="text-warning mb-2">
            <atom-spinner class="animate-pulse" />
          </h5>
          <h5>
            Loading Behaviour Trees...
          </h5>
        </div>
      </div>
    </div>
    <div v-else class="w-100 d-flex justify-content-around">
      <behaviour-tree-table-view
        :behaviour-trees="behaviourTrees"
        :loading-behaviour-tree-id="loadingTree"
        @openBehaviourTreeView="openBehaviourTreeView"
        @openBehaviourTreeEdit="openBehaviourTreeEdit"
        @showModalUpdate="openUpdateModal"
        @showModalCopy="openCopyModal"
        @showModalDelete="openDeleteModal"
      />
    </div>

    <div id="BV_Modals" class="d-none">
      <CreateBehaviourTree @behaviour-tree-created="fetchBehaviourTrees" />
      <UpdateBehaviourTree :bt-object="selectedBehaviourTree" @behaviour-tree-updated="fetchBehaviourTrees" />
      <DeleteBehaviourTree :bt-object="selectedBehaviourTree" @behaviour-tree-deleted="fetchBehaviourTrees" />
      <CopyBehaviourTree :bt-object="selectedBehaviourTree" @behaviour-tree-copied="fetchBehaviourTrees" />
      <ImportBehaviourTree @imported="fetchBehaviourTrees" />
    </div>
  </div>
</template>

<script>
import { computed, onUnmounted, ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'
import BehaviourTreeTableView from '@/views/BehaviourTrees/components/BehaviourTreeTableView.vue'
import CreateBehaviourTree from '@/views/BehaviourTrees/components/modals/CreateBehaviourTree.vue'
import CopyBehaviourTree from '@/views/BehaviourTrees/components/modals/CopyBehaviourTree.vue'
import UpdateBehaviourTree from '@/views/BehaviourTrees/components/modals/UpdateBehaviourTree.vue'
import DeleteBehaviourTree from '@/views/BehaviourTrees/components/modals/DeleteBehaviourTree.vue'
import ImportBehaviourTree from '@/views/BehaviourTrees/components/modals/ImportBehaviourTree.vue'

export default {
  name: 'BehaviourTrees',
  components: {
    AtomSpinner,
    BehaviourTreeTableView,
    CreateBehaviourTree,
    CopyBehaviourTree,
    UpdateBehaviourTree,
    DeleteBehaviourTree,
    ImportBehaviourTree,
  },
  directives: {
    Ripple,
  },
  setup(props, context) {
    const loading = ref(false)
    const loadingTree = ref('')
    const behaviourTrees = computed(() => store.state.behaviourTrees.behaviourTrees)
    const selectedBehaviourTree = ref({ name: '', description: '' })

    onUnmounted(() => {
      store.dispatch('behaviourTrees/clearBehaviourTrees')
    })

    const fetchBehaviourTrees = () => {
      loading.value = true
      store
        .dispatch('behaviourTrees/getAllBehaviourTrees')
        .then(() => { store.dispatch('behaviourTrees/getBehaviourTreesListMetadata') })
        .finally(() => {
          loading.value = false
        })
    }
    fetchBehaviourTrees()

    const openBehaviourTreeView = btObj => {
      loadingTree.value = btObj.id
      store.commit('app/SET_DYNAMIC_PAGE_TITLE', btObj.name)
      store
        .dispatch('behaviours/selectBehaviourTree', btObj.id)
        .then(() => {
          router.push({ name: 'model_behaviour_tree_view', params: btObj.name })
        })
        .catch(e => console.error(e))
        .finally(() => { loadingTree.value = '' })
    }
    const openBehaviourTreeEdit = args => {
      let routeName = 'model_behaviour_tree'
      let btObj = args
      if (Array.isArray(args)) {
        [btObj] = args
        if (args.length > 1 && args[1]) {
          routeName = 'joint_mbse_tree'
        }
      }
      loadingTree.value = btObj.id
      store.commit('app/SET_DYNAMIC_PAGE_TITLE', btObj.name)
      store.dispatch('behaviours/clearSelectedConfigs')
      router.push({ name: routeName, params: { modelId: router.currentRoute.params.modelId, behaviourTreeId: btObj.id } })
      loadingTree.value = ''
    }
    const openBehaviourTreeJoint = async btObj => {
      loadingTree.value = btObj.id
      store.commit('app/SET_DYNAMIC_PAGE_TITLE', btObj.name)
      store.dispatch('behaviours/clearSelectedConfigs')
      await store.dispatch('behaviourTrees/clearSelectedBehaviourTree')
      router.push({ name: 'joint_mbse_tree', params: { behaviourTreeId: btObj.id } })
      loadingTree.value = ''
    }

    const __openModal = (btObj, modalId) => {
      store
        .dispatch('behaviourTrees/getBehaviourTree', btObj.id)
        .then(() => {
          context.root.$bvModal.show(modalId)
        })
    }
    const openCreateModal = () => {
      context.root.$bvModal.show('modal__BehaviourTreeCreate')
    }

    const openImportModal = () => {
      context.root.$bvModal.show('import-bt-modal')
    }
    const openUpdateModal = btObj => {
      __openModal(btObj, 'modal__BehaviourTreeUpdate')
    }
    const openDeleteModal = btObj => {
      __openModal(btObj, 'modal__BehaviourTreeDelete')
    }
    const openCopyModal = btObj => {
      __openModal(btObj, 'modal__BehaviourTreeCopy')
    }
    return {
      loading,
      loadingTree,
      behaviourTrees,
      selectedBehaviourTree,
      fetchBehaviourTrees,
      openBehaviourTreeView,
      openBehaviourTreeEdit,
      openBehaviourTreeJoint,
      openCreateModal,
      openUpdateModal,
      openDeleteModal,
      openCopyModal,
      openImportModal,
    }
  },
}
</script>
