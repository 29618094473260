<template>
  <b-modal
    id="import-bt-modal"
    title="Import Behaviour Tree from JSON"
    size="lg"
    no-close-on-backdrop
    hide-header-close
    @ok="onSubmit"
    @cancel="onReset"
    @hidden="onReset"
  >
    <div v-if="loading">
      <div class="modal-body">
        <h1 class="font-medium-3 text-center">
          Importing Behaviour Tree...
        </h1>
        <h5 class="text-center text-warning">
          <b-spinner />
        </h5>
      </div>
    </div>
    <div v-else>
      <p>This action will:</p>
      <ul>
        <li>Import the selected Behaviour Tree</li>
        <li>Import associated Components</li>
        <li :class="import_requirements ? '' : 'text-danger'">{{ import_requirements ? 'Import' : 'Ignore' }} Requirements</li>
        <li :class="import_issues ? '' : 'text-danger'">{{ import_issues ? 'Import' : 'Ignore' }} Issues</li>
        <li :class="import_tests ? '' : 'text-danger'">{{ import_tests ? 'Import' : 'Ignore' }} Tests</li>
        <li :class="import_notes ? '' : 'text-danger'">{{ import_notes ? 'Import' : 'Ignore' }} Notes</li>
      </ul>
      <p>
        Any matching components will be merged, and new Components will be added to the Ontology
        under a root component named after the imported Behaviour Tree.
      </p>
      <hr />
      <b-form-group label-for="import-bt-file-selector">
        <b-form-file
          id="import-bt-file-selector"
          v-model="selectedFile"
          size="lg"
          accept=".json"
          invalid-feedback="test"
          placeholder="Select JSON file"
          browse-text="Browse"
          :state="Boolean(selectedFile)"
          @input="onFileSelected"
        />
      </b-form-group>
    </div>
    <hr />
    <div class="px-4 w-100 d-inline-flex justify-content-between">
      <b-form-checkbox
        id="req-checkbox"
        v-model="import_requirements"
        :checked="import_requirements"
        name="req-checkbox"
        switch
      >
        Include Requirements
      </b-form-checkbox>
      <b-form-checkbox
        id="iss-checkbox"
        v-model="import_issues"
        :checked="import_issues"
        name="iss-checkbox"
        switch
      >
        Include Issues
      </b-form-checkbox>
      <b-form-checkbox
        id="tst-checkbox"
        v-model="import_tests"
        :checked="import_tests"
        name="tst-checkbox"
        switch
      >
        Include Tests
      </b-form-checkbox>
      <b-form-checkbox
        id="nt-checkbox"
        v-model="import_notes"
        :checked="import_notes"
        name="nt-checkbox"
        switch
      >
        Include Notes
      </b-form-checkbox>
    </div>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button :disabled="loading" variant="outline-secondary" @click="cancel()">
        Discard
      </b-button>
      <b-button :disabled="loading" variant="success" @click="ok()">
        Import Behaviour Tree
      </b-button>
    </template>
  </b-modal>
</template>

<script>
/* eslint-disable camelcase */
import { computed, ref } from '@vue/composition-api'
import store from '@/store'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ImportBTModal',
  setup(props, context) {
    const loading = ref(false)
    const selectedFile = ref(null)
    const fileContents = ref(null)
    const import_requirements = ref(true)
    const import_issues = ref(true)
    const import_tests = ref(true)
    const import_notes = ref(true)

    const browserSupportFileUpload = () => {
      let isCompatible = false
      if (window.File && window.FileReader && window.FileList && window.Blob) {
        isCompatible = true
      }
      return isCompatible
    }

    const onFileSelected = () => {
      if (!browserSupportFileUpload()) {
        fileContents.value = 'The file API is not fully supported in this browser.'
      } else if (selectedFile.value && selectedFile.value.type !== 'application/json') {
        console.warn(`Invalid file type selected: ${selectedFile.value.type}`)
        selectedFile.value = null
        fileContents.value = ''
      } else if (selectedFile.value) {
        const reader = new FileReader()
        reader.readAsText(selectedFile.value)
        reader.onload = evt => { fileContents.value = evt.target.result }
      } else {
        fileContents.value = ''
      }
    }

    const onSubmit = evt => {
      evt.preventDefault()
      if (!selectedFile.value) {
        context.root.$toast({
          component: ToastificationContent,
          props: {
            title: 'Select a JSON file',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }

      loading.value = true
      const params = { model: store.state.model.id }

      axiosIns.post(
        '/api/v2/behaviour/import',
        {
          file: fileContents.value,
          import_requirements: import_requirements.value,
          import_issues: import_issues.value,
          import_tests: import_tests.value,
          import_notes: import_notes.value,
        },
        { params },
      )
        .then(() => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Behaviour Tree imported',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          console.log(error)
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to import Behaviour Tree',
              text: `${error.data}`,
              icon: 'AlertIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          context.emit('imported')
          context.root.$bvModal.hide('import-bt-modal')
          loading.value = false
        })
    }
    const onReset = () => {
      loading.value = false
      selectedFile.value = null
      fileContents.value = ''
      import_requirements.value = true
      import_issues.value = true
      import_tests.value = true
      import_notes.value = true
    }

    return {
      loading,
      selectedFile,
      import_requirements,
      import_issues,
      import_tests,
      import_notes,

      onFileSelected,
      onSubmit,
      onReset,
    }
  },
}
</script>
